<script setup lang="ts">
import { SwiperOptions } from 'swiper/types';
import TopBanner from './TopBanner.vue';

export type TopBannerEntry = {
  content: string;
  title: string;
  countdown_date?: Date;
  hide_banner_date?: Date;
  timer_expired_content?: string;
  timer_expired_link?: string;
  link: string;
} & typeof TopBanner;

const props = defineProps({
  topBanner: {
    type: Object as () => typeof TopBanner,
    default: () => ({}),
  },
});

const banners = computed(() => [...(props.topBanner?.edges || [])]);
const isMultiple = computed(() => banners.value.length > 1);
const swiperOptions: SwiperOptions = {
  navigation: {
    nextEl: '.tbanner-btn-next',
    prevEl: '.tbanner-btn-prev',
  },
  a11y: {
    enabled: true,
  },
  direction: 'vertical',
  effect: 'slide',
  slidesPerView: 1,
  loop: true,
  autoHeight: true,
  speed: 1000,
};
</script>

<template>
  <WrapperSwiper
    v-if="banners?.length > 1"
    :class="[{ 'tbanner-swiper-container': isMultiple }]"
    :swiper-options="swiperOptions"
  >
    <swiper-slide v-for="(bannerEdge, i) in banners" :key="`${i}-${bannerEdge.node.content}`" class="v-hidden">
      <TopBanner :top-banner="bannerEdge.node" />
    </swiper-slide>
    <template v-if="isMultiple" #end>
      <div class="tbanner-btn-prev swiper-desk-and-tab-only">
        <ArrowLeftLine />
      </div>
      <div class="tbanner-btn-next swiper-desk-and-tab-only">
        <ArrowRightLine />
      </div>
    </template>
  </WrapperSwiper>
  <TopBanner v-else-if="banners?.length === 1" :top-banner="banners?.[0]?.node" />
</template>

<style lang="scss" scoped>
.v-hidden {
  visibility: hidden;
}
.fade-up {
  animation: fadeUp 0.5s ease-out forwards;
}

@keyframes fadeUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
.tbanner-swiper-container {
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  list-style: none;
  position: sticky;
  top: 0px;
  padding: 0;
  z-index: 3;
  background-color: #000000;
  height: 40px;
  @include local-mixins.tablet_and_mobile {
    height: 70px;
  }

  @include local-mixins.mobile {
    :deep(p) {
      font-size: 11px;
    }
  }

  @include local-mixins.tablet {
    :deep(p) {
      font-size: 14px;
    }
  }
}

.tbanner-swiper-pagination {
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex !important;
  justify-content: center;
  height: 0.5rem;
  width: 100%;

  :deep(.tbanner-pag-bullet) {
    background-color: gray;
    height: 0.25rem;
    width: 1rem;
    display: inline-block;
    border-radius: 25%;
    cursor: pointer;
    opacity: 1;
    margin: 0 0.25rem;

    &.tbanner-pag-current {
      background-color: white;
      cursor: initial;
    }
  }

  span {
    height: 100%;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: none !important;
  // }
}

.swiper-navs {
  height: 100%;
  position: absolute;
  top: 0;
}

.tbanner-btn-next,
.tbanner-btn-prev {
  // display: none !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: none;
  position: absolute;
  z-index: 10;
  top: 8%;

  @include local-mixins.tablet_and_mobile {
    top: 18%;
  }

  :deep(svg) {
    fill: $color-neutral-white;
  }

  &.swiper-button-disabled {
    :deep(svg) {
      fill: $color-neutral-cool-300;
    }
  }

  &:after {
    content: none;
  }

  // @include local-mixins.desktop_and_tablet {
  //   display: flex !important;
  // }
}
.tbanner-btn-prev {
  margin-right: 1rem;
  left: 1rem;
  right: auto;
}
.tbanner-btn-next {
  margin-left: 1rem;
  right: 1rem;
  left: auto;
}

swiper-container::part(bullet),
swiper-container::part(bullet-active) {
  opacity: 50%;
  margin-top: 10px;
}
swiper-container::part(bullet-active) {
  background-color: $color-primary-500;
}
swiper-container::part(bullet) {
  background-color: $color-neutral-cool-600;
}
</style>
